<template>
	<div>
		<b-overlay :show="isLoading">
			<div class="d-flex">
				<b-card class="p-2 container" :class="origin === 'specimens' ? 'days-counter' : ''" no-body>
					<h3 class="text-uppercase">
						{{
							origin === "others"
								? "Condiciones para Descrestar"
								: origin === "encaste"
								? "Check madrilla"
								: "Dias sin topar"
						}}
					</h3>
					<p v-if="origin === 'others'">
						Los
						<span class="font-weight-bolder">pollones</span>
						que cumplan estas condiciones se mostraran
						<span
							style="color: #7367f0; border-bottom: 1px solid #7367f0"
							class="cursor-pointer"
							@click="showDecrestModal = !showDecrestModal"
						>
							aquí
						</span>
						.
					</p>
					<b-row v-if="origin === 'others'" class="mb-2">
						<b-col cols="12" md="6">
							<b-form-group label="Edad mínima (meses)">
								<vue-number-input center vertical controls v-model="months" :min="1" :max="12" />
							</b-form-group>
						</b-col>
						<b-col cols="12" md="6">
							<b-form-group label="Cantidad mínima de topes">
								<vue-number-input center vertical controls v-model="topes" :min="1" />
							</b-form-group>
						</b-col>
					</b-row>
					<b-row v-else-if="origin === 'specimens'">
						<b-col class="my-1" cols="12" md="11" style="margin-left: auto; margin-right: auto">
							<b-form-group label="Cantidad mínima de dias">
								<vue-number-input center vertical controls v-model="days" :min="21" />
							</b-form-group>
						</b-col>
					</b-row>
					<b-row v-else>
						<b-col class="my-1" cols="12" md="11" style="margin-left: auto; margin-right: auto">
							<b-form-group label="Dias sin registro de huevos">
								<vue-number-input center vertical controls v-model="hours_check" :min="2" />
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col class="text-right">
							<b-button
								size="lg"
								variant="success"
								:disabled="isLoading || !hasChanges"
								@click="setConfig"
							>
								Guardar
							</b-button>
						</b-col>
					</b-row>
				</b-card>
			</div>
		</b-overlay>
		<DecrestModal :show="showDecrestModal" @closing="showDecrestModal = false" />
	</div>
</template>

<script>
import decrestService from "@/services/header/decrest.service"
import DecrestModal from "@/views/amg/header-bookmarks/decrest/DecrestModal.vue"
import SvgDonut from "./SvgDonut.vue"
import { mapActions } from "vuex"

export default {
	components: { DecrestModal, SvgDonut },
	props: {
		action: String,
		origin: String,
	},
	data: () => ({
		isLoading: false,
		months: 9,
		topes: 3,
		days: 21,
		hours_check: 2,
		clearConfig: { months: 0, topes: 0, days: 0, hours_check: 0 },
		showDecrestModal: false,
	}),
	computed: {
		hasChanges() {
			return (
				this.months != this.clearConfig.months ||
				this.topes != this.clearConfig.topes ||
				this.days != this.clearConfig.days ||
				this.hours_check != this.clearConfig.hours_check
			)
		},
	},
	methods: {
		...mapActions({
			A_GET_DAYS_WITHOUT_TOPES_COUNTER: "commons/A_GET_DAYS_WITHOUT_TOPES_COUNTER",
		}),
		async getConfig() {
			this.isLoading = true
			const { data } = await decrestService.getDecrestedConfig()
			this.months = data.find((c) => c.id == 1).number
			this.topes = data.find((c) => c.id == 2).number
			this.days = data.find((c) => c.id == 3).number
			this.hours_check = data.find((c) => c.id == 4).number
			this.clearConfig = {
				months: this.months,
				topes: this.topes,
				days: this.days,
				hours_check: this.hours_check,
			}
			this.isLoading = false
		},
		async setConfig() {
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se actualizara la configuracion para ${
					this.origin === "others"
						? "descreste"
						: this.origin === "encaste"
						? "check madrillas"
						: "dias sin topar"
				}.`,
			})
			if (!isConfirmed) return
			this.isLoading = true
			const decrest_conditions =
				this.origin === "others"
					? [
							{ id: 1, number: this.months },
							{ id: 2, number: this.topes },
					  ]
					: this.origin === "specimens"
					? [{ id: 3, number: this.days }]
					: [{ id: 4, number: this.hours_check }]
			await decrestService.setDecrestedConfig({ decrest_conditions, origin: this.origin })
			if (this.origin == "specimens") await this.A_GET_DAYS_WITHOUT_TOPES_COUNTER()
			this.isLoading = false
			this.getConfig()
			this.showToast("success", "top-right", "Cerebro", "CheckIcon", "Configuracion guardada exitosamente")
		},
	},
	created() {
		this.getConfig()
	},
}
</script>

<style scoped>
.container {
	margin: auto auto;
	width: 100%;
}

@media screen and (min-width: 1024px) {
	.container {
		width: 30%;
	}
}

@media screen and (min-width: 1024px) {
	.days-counter {
		width: 25%;
	}
}
</style>
