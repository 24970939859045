<template>
	<div>
		<h2>Hola mundo</h2>
		<svg ref="svgContainer"></svg>
	</div>
</template>

<script>
// import * as d3 from "d3"

export default {
	name: "SvgDonut",
	data() {
		return {
			dataset: { A: 15, B: 20, C: 35, D: 10, E: 20 },
			height: 500,
			margin: 50,
			width: 500,
		}
	},
	mounted() {
		// const chart = d3.
		// this.generate()
		// this.createDonutChart()
	},
	methods: {
		// generate() {
		// 	const svg = d3.select(this.$refs.svgContainer).attr("width", this.width).attr("height", this.height)

		// 	svg.attr("width", this.width + this.margin + this.margin).attr(
		// 		"height",
		// 		this.height + this.margin + this.margin
		// 	)

		// 	const color = d3
		// 		.scaleOrdinal()
		// 		.domain([
		// 			{
		// 				name: "A",
		// 				num: 15,
		// 			},
		// 			{
		// 				name: "B",
		// 				num: 20,
		// 			},
		// 			{
		// 				name: "C",
		// 				num: 35,
		// 			},
		// 			{
		// 				name: "D",
		// 				num: 10,
		// 			},
		// 			{
		// 				name: "E",
		// 				num: 20,
		// 			},
		// 		])
		// 		.range(["#DA3C78", "#8E44AD", "#0287D0", "#0EAC51", "#F39C12"])

		// 	const g = svg.append("g").attr("transform", "translate(" + this.width / 2 + "," + this.height / 2 + ")")

		// 	const pie = d3.pie().value((d) => d.num)

		// 	const arc = d3.arc().innerRadius(120).outerRadius(200)

		// 	const part = g.selectAll(".part").data(pie(this.dataset)).enter().append("g")

		// 	part.append("path")
		// 		.attr("d", arc)
		// 		.attr("fill", (d, i) => color(i))

		// 	part.append("text")
		// 		.attr("transform", (d) => "translate(" + arc.centroid(d) + ")")
		// 		.text((d) => d.data.name)
		// 		.attr("fill", "white")

		// 	// const data = [10, 25, 15, 30, 20] // Ejemplo de datos para el gráfico

		// 	// const svgWidth = 400
		// 	// const svgHeight = 300
		// 	// const barPadding = 5
		// 	// const barWidth = svgWidth / data.length

		// 	// const svg = d3.select(this.$refs.svgContainer).attr("width", svgWidth).attr("height", svgHeight)

		// 	// const yScale = d3
		// 	// 	.scaleLinear()
		// 	// 	.domain([0, d3.max(data)])
		// 	// 	.range([0, svgHeight])

		// 	// const bars = svg
		// 	// 	.selectAll("rect")
		// 	// 	.data(data)
		// 	// 	.enter()
		// 	// 	.append("rect")
		// 	// 	.attr("y", (d) => svgHeight - yScale(d))
		// 	// 	.attr("height", (d) => yScale(d))
		// 	// 	.attr("width", barWidth - barPadding)
		// 	// 	.attr("transform", (d, i) => `translate(${i * barWidth}, 0)`)
		// 	// 	.attr("fill", "steelblue")
		// },
		// createDonutChart() {
		// 	const data = [
		// 		{
		// 			name: "A",
		// 			num: 10,
		// 		},
		// 		{
		// 			name: "B",
		// 			num: 25,
		// 		},
		// 		{
		// 			name: "C",
		// 			num: 15,
		// 		},
		// 		{
		// 			name: "D",
		// 			num: 30,
		// 		},
		// 		{
		// 			name: "E",
		// 			num: 80,
		// 		},
		// 	] // Ejemplo de datos para el gráfico

		// 	const svgWidth = 400
		// 	const svgHeight = 300
		// 	const radius = Math.min(svgWidth, svgHeight) / 2

		// 	const svg = d3.select(this.$refs.svgContainer).attr("width", svgWidth).attr("height", svgHeight)

		// 	const g = svg.append("g").attr("transform", `translate(${svgWidth / 2},${svgHeight / 2})`)

		// 	const color = d3.scaleOrdinal().domain(data).range(["#DA3C78", "#8E44AD", "#0287D0", "#0EAC51", "#F39C12"])

		// 	const pie = d3
		// 		.pie()
		// 		.value((d) => d.num)
		// 		.sort(null)
		// 		.padAngle(0.02) // Ajustar el espacio entre las secciones

		// 	const arc = d3
		// 		.arc()
		// 		.innerRadius(radius * 0.6)
		// 		.outerRadius(radius)

		// 	const arcs = g.selectAll(".arc").data(pie(data)).enter().append("g").attr("class", "arc")

		// 	arcs.append("path")
		// 		.attr("d", arc)
		// 		.attr("fill", (d) => color(d.data))
		// 		.transition() // Agregar transición para animar los arcos
		// 		.duration(1000) // Duración de la animación en milisegundos
		// 		.attrTween("d", function (d) {
		// 			const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d)
		// 			return function (t) {
		// 				return arc(interpolate(t))
		// 			}
		// 		})

		// 	arcs.append("text")
		// 		.attr("transform", (d) => `translate(${arc.centroid(d)})`)
		// 		.attr("text-anchor", "middle")
		// 		.text((d) => d.data.name)
		// 		.style("fill", "#fff")

		// 	// Cambiar el color de la sombra aquí (por ejemplo, rojo)
		// 	// filter.append("feFlood").attr("flood-color", "red")

		// 	const centerCircle = g
		// 		.append("circle")
		// 		.attr("r", radius * 0.4)
		// 		.attr("fill", "white")
		// 		.attr("filter", "url(#drop-shadow)") // Añadir sombra al círculo

		// 	const centerText2 = g
		// 		.append("text")
		// 		.attr("text-anchor", "middle")
		// 		.attr("dy", "0em") // Desplazamiento hacia abajo para centrar el texto en el eje Y
		// 		.text(data.reduce((accumulator, currentValue) => accumulator + currentValue.num, 0))
		// 		.style("font-size", "0") // Establecer tamaño inicial del texto a 0
		// 		.style("filter", "url(#drop-shadow-text)") // Añadir sombra al texto
		// 		.transition() // Agregar transición para animar el aumento de tamaño
		// 		.duration(1000) // Duración de la animación en milisegundos
		// 		.style("font-size", "40px") // Tamaño final del texto después de la animación
		// 		.style("font-weight", "bolder")
		// 		.style("fill", "#434343")

		// 	const centerText = g
		// 		.append("text")
		// 		.attr("text-anchor", "middle")
		// 		.attr("dy", "1.5em")
		// 		.text(`Peleas`)
		// 		.style("font-size", "0") // Establecer tamaño inicial del texto a 0
		// 		.style("filter", "url(#drop-shadow-text)") // Añadir sombra al texto
		// 		.transition() // Agregar transición para animar el aumento de tamaño
		// 		.duration(1000) // Duración de la animación en milisegundos
		// 		.style("font-size", "18px") // Tamaño final del texto después de la animación
		// 		.style("fill", "#909090")
		// 		.style("font-weight", "bold")

		// 	// Crear la sombra del círculo usando un filtro
		// 	const defs = svg.append("defs")

		// 	const filter = defs
		// 		.append("filter")
		// 		.attr("id", "shadow")
		// 		.attr("x", "-50%")
		// 		.attr("y", "-50%")
		// 		.attr("width", "200%")
		// 		.attr("height", "200%")

		// 	filter
		// 		.append("feGaussianBlur")
		// 		.attr("in", "SourceAlpha")
		// 		.attr("stdDeviation", 3) // Ajusta este valor para controlar el desenfoque de la sombra
		// 		.attr("result", "blur")

		// 	filter
		// 		.append("feOffset")
		// 		.attr("in", "blur")
		// 		.attr("dx", 0) // Ajusta estos valores para controlar la dirección de la sombra
		// 		.attr("dy", 1)
		// 		.attr("result", "offsetBlur")
		// 		.style("fill", "red")

		// 	// Agregar componente de mezcla de colores (feColorMatrix)
		// 	filter.append("feFlood").attr("flood-color", "rgba(0, 0, 0, 0.05)")

		// 	filter.append("feComposite").attr("in2", "offsetBlur").attr("operator", "in")

		// 	const feMerge = filter.append("feMerge")
		// 	feMerge.append("feMergeNode").attr("in", "offsetBlur")
		// 	feMerge.append("feMergeNode").attr("in", "SourceGraphic")

		// 	// Aplicar la sombra al círculo
		// 	centerCircle.attr("filter", "url(#shadow)")
		// },
	},
}
</script>
